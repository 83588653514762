import { Component } from 'react';
import isEmpty from 'Lib/utilities/isEmpty';

class Text extends Component {
    render() {
        const { value, emptyText, defaultValue, nl2br = false, ...rest } = this.props;

        const val = isEmpty(value) ? defaultValue : value;

        if (isEmpty(val)) {
            return <span {...rest}>{emptyText}</span>;
        }

        if (nl2br) {
            if (typeof val === 'object' && val !== null) {
                return (
                    <span>
                        {Object.keys(val).map(key => {
                            const currentValue = val[key];
                            if (typeof currentValue === 'string') {
                                return (
                                    <span key={key}>
                                        {currentValue.split('\n').map((text, textIndex) => (
                                            <span key={textIndex}>
                                                {text}
                                                <br />
                                            </span>
                                        ))}
                                    </span>
                                );
                            }

                            return <span key={key}>{currentValue}</span>;
                        })}
                    </span>
                );
            }
        }

        // If nl2br is not enabled, just render the value as it is
        return <span {...rest}>{val}</span>;
    }
}

export default Text;
